const work_data: Array<Record<string, any>> = [
  {
    name: "Equity Bank Limited",
    time: "Oct, 2021 - Present",
    title: "Mid Level Backend Developer",
  },
  {
    name: "Transfer Multisort Electronik",
    time: "Sep, 2018 - Jul 2022",
    title: "TME Education Ambassador",
  },
  {
    name: "Poshak Consultancy",
    time: "Mar, 2020 - July, 2021",
    title: "Co-Founder/CTO",
  },
];

export default work_data;
